<template>
  <base-section
    id="map"
    space="0"
    class="mt-12"
  >
    <base-section-heading :title="translations.homeMapTitle" />
    <div style="height: 500px; width: 100%">
      <l-map
        v-if="showMap"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />
        <l-marker
          v-for="place in places"
          :key="place.address"
          :lat-lng="place.geo"
        >
          <l-tooltip :options="{ permanent: true, interactive: true }">
            <div>
              {{ place.address }}
            </div>
          </l-tooltip>
        </l-marker>
      </l-map>
    </div>
  </base-section>
</template>

<script>
  import { latLng, Icon } from 'leaflet'
  import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from 'vue2-leaflet'
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_HOME } from '@/views/home/Index.vue'

  delete Icon.Default.prototype._getIconUrl
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  })

  export default {
    name: 'SectionHomeMap',
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LTooltip,
    },
    data () {
      return {
        zoom: 6,
        center: latLng(52.594140, 19.653090),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        withPopup: latLng(47.41322, -1.219482),
        withTooltip: latLng(47.41422, -1.250482),
        currentZoom: 4.5,
        currentCenter: latLng(52.594140, 19.653090),
        showParagraph: false,
        mapOptions: {
          zoomSnap: 0.5,
          scrollWheelZoom: false,
          touchZoom: false,
        },
        showMap: true,
        places: [
          {
            geo: latLng(50.043430, 19.275210),
            address: 'ul. Lipowa 5 \b 32-600 Oświęcim',
          },
          {
            geo: latLng(52.594140, 19.653090),
            address: 'ul. Witolda Zglenickiego 50 F \b 09-400 Płock',
          },
          {
            geo: latLng(54.345110, 18.723830),
            address: 'ul. Elbląska 135 \b 80-718 Gdańsk',
          },
          {
            geo: latLng(51.451840, 21.980860),
            address: 'Aleja 1000-Lecia Państwa Polskiego 13\n 24-100 Puławy',
          },
        ],
      }
    },
    computed: {
      ...mapState(STORE_NAMESPACE_HOME, [
        'translations',
      ]),
    },
    methods: {
      zoomUpdate (zoom) {
        this.currentZoom = zoom
      },
      centerUpdate (center) {
        this.currentCenter = center
      },
    },
  }
</script>
